<template>
  <div class="container-fluid annotatorParagraphs">
    <LoaderComponent v-if="loading" />
    <div class="container">
      <h1>Paragraphs Annotator</h1>
      <div class="annotator__box">
        <div class="annotator__infobox">
          <div class="annotator__infobox--info info--global">
            <h3>Parágrafos Finalizados</h3>
            <p>{{ finalizadosGlobal }}</p>
          </div>
          <div class="annotator__infobox--info info--global">
            <h3>Parágrafos não Finalizados</h3>
            <p>{{ naoFinalizadosglobal - notfinished_three}}</p>
          </div>
          <div class="annotator__infobox--info info--global">
            <h3>NF 3 anotações</h3>
            <p>{{ notfinished_three }}</p>
          </div>          
          <div class="annotator__infobox--info info--global">
            <h3>% Parágrafos Finalizados</h3>
            <p>{{ porcentagemFinalizados }}</p>
          </div>
        </div>
        <div class="annotator__datefilter">
          <date-range-picker
            :ref="`picker`"
            v-model="dateRange"
            @update="getAnnotationsbydate()"
          >
            <template v-slot:input=""> Escolha o Range de datas </template>
          </date-range-picker>
        </div>

        <div class="annotator__infobox">
         
          <div class="annotator__infobox--info">
            <h3>Total de Parágrafos</h3>
            <p>{{ annotations_count }}</p>
          </div>
         
          <div class="annotator__infobox--info">
            <h3>Parágrafos finalizados</h3>
            <p>{{ annotations_finished }}</p>
          </div>
          <div class="annotator__infobox--info">
            <h3>Parágrafos não finalizados</h3>
            <p>{{ annotations_notfinished }}</p>
          </div>
            <div class="annotator__infobox--info">
            <h3>Média de Finalizados</h3>
            <p>{{ mediafinalizados }}</p>
          </div>
         

        </div>

        <div class="annotator__listusers">
          <ListAnnotators
            v-if="analytics_obj"
            :title="`Anotadores`"
            :rows.sync="analytics_obj.userdata"
            :type="'users'"
            :numberpages="5"
          />
        </div> 

        <div class="annotator__chartannotators">
          <Barchart
            v-if="personsChart != null"
            :chartData.sync="__personCharts.line.data"
            :chartOptions.sync="__personCharts.options"
            :styles.sync="__personCharts.line.styles"
          />

          <Barchart
            v-if="finishedCharts != null"
            :chartData.sync="__finishedCharts.line.data"
            :chartOptions.sync="__finishedCharts.options"
            :styles.sync="__finishedCharts.line.styles"
          />
        </div>

        <h2>Filtrar por:</h2>
        <div class="annotator__filters">
          <select
            v-model="filtrarUser"
            @change="filterRevisao(filtrarUser, 'user')"
          >
            <option selected>Revisões</option>
            <option :value="1">1 anotador</option>
            <option :value="2">2 anotadores</option>
            <option :value="3">3 anotadores</option>
          </select>
          <input
            type="text"
            placeholder="Tipo"
            v-model="filtrarTipo"
            @change="filterTipo(filtrarTipo, 'paragraph_type')"
          />
          <input
            type="text"
            placeholder="Paragraph ID"
            v-model="filtrarParagrafo"
            @change="filterTipo(filtrarParagrafo, 'paragraph_id')"
          />
          <select
            v-model="filtrarFinished"
            @change="filterStatus(filtrarFinished, 'finished')"
          >
            <option selected>Status</option>
            <option :value="`all`">Todos</option>
            <option :value="true">Finalizado</option>
            <option :value="false">Não finalizado</option>
          </select>
        </div>
        <transition-group name="fade">
          <template v-if="annotations.length > 0">
            
            <div
              class="annotator__paragraphs"
              v-for="(an, key) in __annotationsPaginated"
              :key="key"
            >
              <div
                class="annotator__paragraphs__header not-finished--header"
                @click="toggleVisible(key)"
              >
                <div
                  class="paragraphs__header--marker"
                  :class="{
                    finished: an.finished,
                    'not-finished': !an.finished,
                  }"
                ></div>
                <div class="paragraphs__header--invalidate" v-if="!an['finished'] && an['user'].length === 3 && an['checked'] === false" @click="sendNotverified(key,an['paragraph_id'])"></div>

                <div class="paragraphs__header--info">
                  MATRÍCULA ID: {{ an.matricula_id }}
                </div>
                <div class="paragraphs__header--info">
                  PARAGRAPH ID: {{ an.paragraph_id }}
                </div>
                <div class="paragraphs__header--info">
                  ATUALIZADO EM: {{ an.updated_at }}
                </div>
                <div class="paragraphs__header--info">
                  TIPO: {{ an.paragraph_type }}
                </div>
              </div>
             
              <div
                class="annotator__paragraphs__infos shadow"
                :class="{ visible: an.visible, invisible: !an.visible }"
              >
               
                <div
                  class="annotator__infos--text"
                  v-for="(us, key_) in an.user"
                  :key="key_"
                  :class="{'correct':us.condition === 'correct','incorrect':us.condition === 'incorrect'}"
                >
                  
                  <div class="annotator__infos--annotator">
                    Anotador: {{ us.name }}<br />
                    Data: {{ us.timestamp }}
                    
                  </div>


                    <div v-html="us.paragraph"></div>
                    <div class="paragraphType">Tipo:{{us.data}}</div>
                  <button v-if="!an['finished'] && an['user'].length === 3" @click="validateParagraph(key,an['paragraph_id'],an['matricula_id'],us,key_)">Validar Parágrafo</button>
                </div>
                 
              </div>
            </div>
          </template>
        </transition-group>
        <div class="annotator__pagination">
          <div class="row" v-if="paginationType === 'fast'">
            <div class="pagination">
              <a
                v-for="(page, key) in pages"
                :key="key"
                :class="`${page.status}`"
                @click="changePage(page.page)"
                >{{ page.page }}</a
              >
            </div>
          </div>
          <div class="row pages" v-if="paginationType === 'long'">
            <div class="col-md-2">
              <div class="pagination">
                <a :class="`${pages[0].status}`" @click="previousPage()">{{
                  pages[0].page
                }}</a>
                <a :class="`${pages[1].status}`" @click="nextPage()">{{
                  pages[1].page
                }}</a>
              </div>
            </div>
            <div class="col-md-2">
              <h3>{{ pageLongIndex }} de {{ totalPages }} páginas</h3>
            </div>
            <div class="col-md-4">
              <input
                class="form-group"
                type="text"
                v-model="searchPage"
                placeholder="Procurar página..."
                @change="inputChange(searchPage)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import { AnnotatorParagraphsMixin } from "./Annotatorparagraphs.mixin.js";
import Barchart from "../../components/ui/Barchart/Barchart";
import DateRangePicker from "vue2-daterange-picker";
import ListAnnotators from "../../components/ui/list-analytics-annotators/list-analytics-annotators";
import LoaderComponent from "../../components/ui/loader/loader";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  data() {
    return {};
  },
  watch: {},
  props: {},
  components: {
    Barchart,
    DateRangePicker,
    ListAnnotators,
    LoaderComponent
  },
  name: "Annotator",
  mixins: [AnnotatorParagraphsMixin],
};
</script>
<style src="./Annotatorparagraphs.less" lang="less" />
