import {
    getAnnotations,
    getAnnotationsbyDate,
    getAnnotationsglobal,
    postVerified,
    sendNotverified as _sendNotverified
} from "../../services/annotator.services";
import {
    Annotatorscount__piechart,
    Annotatorsfinished__piechart,
    Annotatorsperson__piechart,
} from "../../factories/staticGraphicFactory";
import { formatTime, paginateFields } from "../../helpers/helpers";
import {get } from '../../services/logs.services'
import moment from 'moment';
export const AnnotatorParagraphsMixin = {
    data() {
        return {
            allannotations: 0,
            annotations: [],
            byname_annotations: false,
            annotations_correction: false,
            finalizadosGlobal: 0,
            naoFinalizadosglobal: 0,
            porcentagemFinalizados: 0,
            annotationsPaginated: {},
            annotations_count: 0,
            annotations_finished: 0,
            annotations_notfinished: 0,
            mediafinalizados: 0,
            analytics_data: [],
            analytics_obj: false,
            paragraphText: '',
            personsChart: null,
            countCharts: null,
            finishedCharts: null,
            no_annotations: 0,
            annotators_count: {},
            filtrarTipo: "",
            filterAnnotations: [],
            filtrarFinished: 'all',
            filtrarParagrafo: '',
            filtrarUser: 1,
            dataSearch: new Date(),
            pages: [],
            typeField: {},
            paginationType: "fast",
            pageLongIndex: 1,
            pageIndex: 1,
            totalPages: 0,
            searchPage: '',
            numberpages: 10,
            dateRange: {
                startDate: new Date(),
                endDate: new Date(),
            },
            loading: false,
            notfinished_three: 0,
            matriculasCorrigidas: 0,
            query: {
                logGroupName: 'admin_annotators',
                logStreamName: 'admin_annotators_data',
                limit: 10000
            }
            
        };
    },
    created() {},
    mounted() {
        this.getAnnotationsfromdb();
        this.getGlobal()
    },
    filters: {},
    watch: {},
    computed: {
        __annotations() {
            return this.annotations;
        },
        __annotationsPaginated() {
            return this.annotationsPaginated.data;
        },
        __personCharts() {
            return this.personsChart;
        },
        __countCharts() {
            return this.countCharts;
        },
        __finishedCharts() {
            return this.finishedCharts;
        },
    },
    methods: {
        async getAnnotationsfromdb() {
            try {
                this.loading = true
                this.byname_annotations = []
                const { data } = await getAnnotations('paragraphs');
                console.log(data)
                this.analytics_data = await this.getFromcl()
                this.filterAnnotations = this.orderAnnotations(this.$duplicate(data.data.annotator_data));
                this.annotations = this.$duplicate(this.filterAnnotations)
                this.allannotations = data.data.allannotations
                this.byname_annotations = this.$duplicate(data.data.byname_annotations)
                this.paginateAnnotations()
                this.annotations_count = this.$duplicate(data.data.annotations_count);
                this.no_annotations = this.$duplicate(data.data.no_annotations);
                this.annotators_count = this.$duplicate(data.data.annotators_count);
                this.annotations_finished = data.data.finalizadas;
                this.matriculasCorrigidas = data.data.correction_count;
                this.annotations_notfinished = data.data.naofinalizadas;
                this.mediafinalizados = `${Math.round(this.annotations_finished / this.annotations_count * 100)}%`
                this.mountAnalytics()
                this.mountSpans()
                this.mountPersonschart();
                this.mountFinishedchart();
                this.loading = false
            } catch (e) {
                this.$tokentest(e.response.status)
                this.loading = false
                this.$toast.error('Ocorreu um erro')
            }

        },

        orderAnnotations(data) {
            data = data.map(val => {
                val.timestamp = new Date(val.updated_at)
                return val
            }).sort((a, b) => {
                return b.timestamp - a.timestamp
            })

            return data

        },

        async getFromcl() {
            try {
                const { data } = await get(this.query)
                let logs = this.$duplicate(data.data.logs)
                logs = logs.map(val => {
                    val.message = JSON.parse(val.message)
                    return val.message
                })
                return logs
            } catch (e) {
                this.$tokentest(e.response.status)
                return e
            }
        },
        async getGlobal() {
            try {
                const { data } = await getAnnotationsglobal("paragraphs")

                this.finalizadosGlobal = data.data.finished_paragraphs
                this.naoFinalizadosglobal = data.data.not_finished_paragraphs
                this.notfinished_three = data.data.notfinished_three
                this.porcentagemFinalizados = `${Math.round(this.finalizadosGlobal / (this.finalizadosGlobal + this.naoFinalizadosglobal) * 100)}%`
            } catch (e) {
                this.$tokentest(e.response.status)
                console.log(e)
            }


        },

        async sendNotverified(key, paragraph_id) {
            let confirm = window.confirm('Você tem certeza que quer invalidar este parágrafo?')
            if (confirm) {
                const send = {
                    paragraph_id: paragraph_id
                }
                try {
                    await _sendNotverified(send, 'paragraphs')
                    this.__annotationsPaginated[key]['checked'] = true
                    for (const i in this.annotations) {
                        if (this.annotations[i]['paragraph_id'] == paragraph_id) {
                            this.annotations[i]['checked'] = true
                        }
                    }
                    this.$toast.success('Parágrafo salvo com sucesso')

                } catch (e) {
                    this.$tokentest(e.response.status)
                    this.$toast.error('Problema ao marcar o parágrafo')
                }
            } else {
                return
            }
        },
        mountAnalytics() {

            this.analytics_obj = new Object()
            if (this.analytics_data.length > 0) {
                this.analytics_data = this.analytics_data.filter(val => { return val.username != undefined })
            } else {
                this.analytics_data = []
            }
            this.byname_annotations = this.byname_annotations.map((val_) => {

                if (this.analytics_data.length > 0) {
                    val_.data = this.analytics_data.map(val => {
                        if (val.username == val_.name) {
                            return val.totaltime
                        }
                    }).filter(val => { return val != undefined })
                    if (val_.data.length > 0) {
                        val_.time = val_.data.map(val => { return val }).reduce((ac, val) => { return ac + val })
                    } else {
                        val_.time = 0
                    }
                    val_.timehour = formatTime(val_.time)
                } else {
                    val_.data = []
                    val_.time = 0
                    val_.timehour = 0
                }
                if (val_.finished > 0) {
                    val_.accuracy = `${Math.round(val_.correct/val_.finished *100)}%`
                } else {
                    val_.accuracy = '0%'
                }
                return val_
            })

            if (this.analytics_data.length > 0) {
                this.analytics_obj.errorspans = this.analytics_data.map(val => { if (val.modelspanuse) return val.modelspanuse }).filter(val => { return val != undefined }).length
                this.analytics_obj.totaltime = this.analytics_data.map(val => { return val.totaltime }).reduce((ac, val) => { return ac + val })
                this.analytics_obj.totaltimehour = formatTime(this.analytics_obj.totaltime)
                this.analytics_obj.totaldata = this.analytics_data.length
                this.analytics_obj.annotationtimemedia = formatTime((this.analytics_obj.totaltime / this.analytics_obj.totaldata))
                this.analytics_obj.errormedia = `${((this.analytics_obj.errorspans / this.analytics_obj.totaldata) * 100).toFixed(2)}%`
            } else {
                this.analytics_obj.errorspans = 0
                this.analytics_obj.totaltime = 0
                this.analytics_obj.totaltimehour = formatTime(0)
                this.analytics_obj.totaldata = 0
                this.analytics_obj.annotationtimemedia = formatTime(0)
                this.analytics_obj.errormedia = `0%`
            }

            this.analytics_obj.userdata = this.byname_annotations
            console.log(this.analytics_obj.userdata)

            this.$forceUpdate()

        },
        toggleVisible(key) {
            this.annotationsPaginated.data[key].visible = !this.annotationsPaginated.data[key].visible;
            for (let i in this.annotationsPaginated.data) {
                if (i != key) {
                    this.annotationsPaginated.data[i].visible = false;
                }
            }
        },
        filterTipo(value, type) {

            this.annotations = this.filterAnnotations;
            this.mountSpans()
            this.annotations = this.__annotations.filter((val) => {
                if (val[type].match(value)) {
                    return val;
                }
            });
            this.annotations = this.orderAnnotations(this.annotations)
            this.paginateAnnotations()
        },
        filterRevisao(value, type) {

            this.annotations = this.filterAnnotations;
            this.mountSpans()
            this.annotations = this.__annotations.filter((val) => {
                if (this.filtrarFinished === 'all') {
                    if (val[type].length === value) {
                        return val;
                    }
                } else {
                    if (val[type].length === value && val['finished'] === this.filtrarFinished) {
                        return val;
                    }
                }

            });
            this.annotations = this.orderAnnotations(this.annotations)
            this.paginateAnnotations()
        },
        filterStatus(value, type) {
            this.annotations = this.filterAnnotations;
            this.mountSpans()
            if (this.filtrarUser === '' || this.filtrarUser === null) {
                this.filtrarUser = 1
            }
            this.annotations = this.__annotations.filter((val) => {
                if (val[type] === value && val['user'].length === this.filtrarUser) {
                    return val;
                }
            });
            this.annotations = this.orderAnnotations(this.annotations)
            this.paginateAnnotations()

        },
        async getAnnotationsbydate(value) {
            try {
                this.loading = true
                this.byname_annotations = []
                let obj = {
                    'datafrom': '',
                    'datato': '',
                }
                obj.datafrom = moment(this.dateRange.startDate).locale('en').format('YYYY-MM-DD')
                obj.datato = moment(this.dateRange.endDate).locale('en').format('YYYY-MM-DD')
                this.query.startTime = moment(this.dateRange.startDate).format('L')
                this.query.endTime = moment(this.dateRange.endDate).format('L')
                const { data } = await getAnnotationsbyDate(obj,'paragraphs');

                this.analytics_data = await this.getFromcl(this.query)
                this.filterAnnotations = this.orderAnnotations(this.$duplicate(data.data.annotator_data));
                this.annotations = this.$duplicate(this.filterAnnotations)
                this.allannotations = data.data.allannotations
                this.byname_annotations = this.$duplicate(data.data.byname_annotations)
                this.paginateAnnotations()
                this.annotations_count = this.$duplicate(data.data.annotations_count);
                this.no_annotations = this.$duplicate(data.data.no_annotations);
                this.annotators_count = this.$duplicate(data.data.annotators_count);
                this.annotations_finished = data.data.finalizadas;
                this.annotations_notfinished = data.data.naofinalizadas;
                this.matriculasCorrigidas = data.data.correction_count;
                this.mediafinalizados = `${Math.round(this.annotations_finished / this.annotations_count * 100)}%`
                this.mountAnalytics()
                this.mountSpans()
                this.mountPersonschart();
                this.mountFinishedchart();
                this.loading = false
            } catch (e) {
                this.$tokentest(e.response.status)
                this.loading = false
                this.$toast.error('Ocorreu um erro')
            }

        },

        paginateAnnotations() {
            this.annotationsPaginated = new Object()
            this.annotationsPaginated = paginateFields(this.annotations, 1, this.numberpages)
            this.$nextTick(() => {
                this.mountPages(this.annotationsPaginated.total_pages)
            })
        },
        mountPersonschart() {
            let data = this.annotators_count;
            this.personsChart = new Annotatorsperson__piechart(
                data,
                "Anotações por usuário"
            );
        },
        mountCountschart() {
            let data = {
                labels: ["Anotados", "Não anotados"],
                count: [this.annotations_count, this.no_annotations],
            };
            this.countCharts = new Annotatorscount__piechart(
                data,
                "Parágrafos anotados"
            );
        },
        mountFinishedchart() {
            let data = {
                labels: ["Finalizados", "Não finalizados"],
                count: [this.annotations_finished, this.annotations_notfinished],
            };
            this.finishedCharts = new Annotatorsfinished__piechart(
                data,
                "Parágrafos anotados"
            );
        },
        mountSpans() {
            this.$nextTick(() => {
                for (let j in this.annotations) {
                    this.annotations[j].user = this.annotations[j].user.map(val => {
                        val.text = this.annotations[j].text
                        let paragraph = val.text.split('')
                        for (let i in val.data) {
                            for (let it = 0; it <= paragraph.length; it++) {
                                let id = parseInt(Math.random() * (20000000000000000000 - 1000000000000000) + 1000000000000000);
                                if (val.data[i].start === it) {
                                    paragraph[it] = `<span class="${val.data[i].label}" style="${val.data[i]['color']} start="${val.data[i].start}" end="${val.data[i].end}" id="${id}">${paragraph[it]}`
                                }
                                if (val.data[i].end === it) {
                                    paragraph[it] = `</span>`
                                }
                            }
                        }
                        val.paragraph = paragraph.join('')
                        return val
                    })
                }
            })
        },
        changePage(index) {
            this.pageLongIndex = index
            this.annotationsPaginated = paginateFields(
                this.annotations,
                this.pageLongIndex,
                this.numberpages
            );

            this.pages = this.pages.map((val) => {
                if (val.page === index) val.status = "active";
                else val.status = "inactive";
                return val;
            });
            this.$forceUpdate();

        },
        mountPages(totalPages) {
            this.pages = [];
            this.totalPages = totalPages;
            if (totalPages < 15) {
                this.paginationType = "fast";
                for (let i = 1; i <= totalPages; i++) {
                    this.pages.push({
                        page: i,
                        status: "inactive",
                    });
                    this.pages[0].status = "active";
                }
            } else {
                this.paginationType = "long";
                this.pages.push({
                    page: "Next",
                    status: "next",
                });
                this.pages.unshift({
                    page: "Previous",
                    status: "previous",
                });
            }
        },
        previousPage() {
            if (this.pageLongIndex === 1) {
                this.pageLongIndex = this.totalPages;
            } else {
                this.pageLongIndex = this.pageLongIndex - 1;
            }
            this.annotationsPaginated = paginateFields(
                this.annotations,
                this.pageLongIndex,
                this.numberpages
            );
            console.log(this.annotationsPaginated)

        },
        nextPage() {
            if (this.pageLongIndex === this.totalPages) {
                this.pageLongIndex = 1;
            } else {
                this.pageLongIndex = this.pageLongIndex + 1;
            }
            this.annotationsPaginated = paginateFields(
                this.annotations,
                this.pageLongIndex,
                this.numberpages
            );

        },
        inputChange(index) {
            if (index > this.totalPages) {
                this.pageLongIndex = this.totalPages
            } else if (index < 1) {
                this.pageLongIndex = 1
            } else {
                this.pageLongIndex = index
            }
            this.annotationsPaginated = paginateFields(
                this.annotations,
                this.pageLongIndex,
                this.numberpages
            );

        },
        async validateParagraph(key, paragraph_id, matricula_id, data, dataid) {
            let datacopy = this.$duplicate(data)
            let confirm = window.confirm('Você tem certeza que quer validar este parágrafo?')
            if (confirm) {
                const verified = {
                    paragraph_id: paragraph_id,
                    user_index: dataid,
                    matricula_id: matricula_id
                }
                try {
                    delete datacopy['paragraph']
                    delete datacopy['condition']
                    console.log(verified)
                    await postVerified(verified, 'paragraphs')
                    this.__annotationsPaginated[key]['finished'] = true
                    this.__annotationsPaginated[key]['user'][dataid]['condition'] = 'correct'
                    for (const i in this.__annotationsPaginated[key]['user']) {
                        if (i != dataid) {
                            this.__annotationsPaginated[key]['user'][i]['condition'] = 'incorrect'
                        }
                    }
                    for (const i in this.annotations) {
                        if (this.annotations[i]['paragraph_id'] == paragraph_id) {
                            this.annotations[i]['finished'] = true
                            this.annotations[i]['user'][dataid]['condition'] = 'correct'
                            for (const j in this.annotations[i]['user']) {
                                if (j != dataid) {
                                    this.annotations[i]['user'][j]['condition'] = 'incorrect'
                                }
                            }
                        }
                    }
                    this.$toast.success('Parágrafo validado com sucesso')

                } catch (e) {
                    this.$tokentest(e.response.status)
                    this.$toast.error('Erro ao salvar o parágrafo validado')
                }


            } else {
                return
            }
        }
    },
};